import Language from "../../Interfaces/Language";

export const en_us: Language = {
  help: "Need Help?",
  helpCenter: "Help Center",
  helpCenterLink: "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/",
  expect: "What to expect on these tests",
  expectLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/collections/3396642-about-criteria-assessments",
  faq: "Frequently Asked Questions",
  faqLink: "/home/faq",
  copyright: "Copyright",
  copyrightNotices: "Copyright Notices",
  copyrightNoticesLink: "/home/copyright",
  terms: "Terms of Use",
  termsLink: "/home/terms",
  privacy: "Privacy Statement",
  privacyLink: "/home/privacy",
  dmca: "DMCA Policy",
  dmcaLink: "/home/dmca",
  accommodations: "Disability / Accommodations",
  accommodationsLink: "/home/accommodations",
  technicalSupport: "Technical Support",
  chatSupport:
    "For support, please chat with our team on the bottom right hand corner of this page"
};
