export const nodeApiURL = 'https://oda-node-server-dev.ondemandassessment.com';
export const odaURL = 'https://playground.ondemandassessment.com';
export const apiURL = 'https://api-dev.criteriacorp.com/api/oda';
export const sskowk = 'n4Ws7sHmGQJ3w0GJ';
export const environment = 'development';
export const FPKey = 'JY7MbU7crz6YHKM3Jk7V';
export const talentInsightsURL = 'https://api-dev.criteriacorp.com/api/v1';
export const talentInsightsLink = 'https://talentinsights-dev.criteriacorp.com';
export const configCatAppKey = 'wmPYCHgfSk6BY6_oMK2s8w/iZQCWSuP90ajlVJym9u0fg';
export const amplitudeKey = '7e05c06d3014eb7129358694f623b5e2';
export const sentryDSN = 'https://bb267e7424404f8eafb3970487d68821@sentry.io/1436902';
