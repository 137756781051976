import React, { Component } from "react";
import { Form } from "react-bootstrap";
import ApplicationQuestions from "../../Interfaces/ApplicationQuestions";
import { TestEventConsumer } from "../../Contexts/TestEventContext";

interface Props {
  question: ApplicationQuestions;
  onChangeHandler: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  missingQuestions: string[];
}

interface State {
  selectValue: string;
}

export default class ApplicationSelect extends Component<Props, State> {
  state = {
    selectValue: ""
  };

  // define the context type so that we can use this.context throughout the class
  static contextType: any = TestEventConsumer;

  handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectValue: event.target.value });
    this.props.onChangeHandler(event);
  };
  render() {
    const { missingQuestions } = this.props;
    const {
      label,
      selectList,
      applicationQuestionId,
      required
    } = this.props.question;

    // if the question was required, and the application was submitted without an answer, change the
    // background color as an indication.
    const backgroundColor = missingQuestions.includes(
      `q_${applicationQuestionId}`
    )
      ? "#F7CECD"
      : "";

    return (
      <Form.Group>
        <Form.Label
          style={{ backgroundColor }}
          htmlFor={`${applicationQuestionId}`}
        >
          {/* Doing this without bootstrap to make life easier later */}
          {required ? (
            <>
              <i aria-hidden="true">*</i> {label}
              <span className="sr-only">Required</span>
            </>
          ) : (
            label
          )}
        </Form.Label>
        <Form.Control
          as="select"
          name={`q_${applicationQuestionId}`}
          id={`q_${applicationQuestionId}`}
          value={this.state.selectValue}
          onChange={this.handleSelect}
        >
          <option value="">
            {this.context &&
            this.context?.testEventData?.translatedText?.selectAnswer
              ? this.context.testEventData.translatedText.selectAnswer
              : "Select an answer"}
          </option>
          {selectList
            ? Object.keys(selectList.options).map(key => (
                <option value={key} key={key}>
                  {selectList.options[key]}
                </option>
              ))
            : null}
        </Form.Control>
      </Form.Group>
    );
  }
}
