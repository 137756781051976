import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FaRegEnvelope, FaCheckCircle } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import LandingPageParameters from "../Interfaces/LandingPageParameters";

interface Props {
  landingPageStyle: LandingPageParameters;
  eventId: string;
  handleEmailEventId: () => void;
  emailSent: boolean;
  emailSentLoading: boolean;
  yourEventId: string;
  uniqueIdentifier: string;
  eventIdPurpose: string;
  eventIdEmailSuccess: string;
  emailEventId: string;
}

const EventIdv2 = ({
  landingPageStyle,
  eventId,
  handleEmailEventId,
  emailSent,
  emailSentLoading,
  yourEventId,
  uniqueIdentifier,
  eventIdPurpose,
  eventIdEmailSuccess,
  emailEventId
}: Props) => {
  // logic for displaying the eventId based on the length
  const eventIdText = `${eventId.slice(0, 3)}-${eventId.slice(
    3,
    7
  )}-${eventId.slice(7, 11)}${
    eventId.length === 11 ? null : `-${eventId.slice(11)}`
  }`;

  const eventIdTextTransormStyle =
    eventId?.length === 11 ? "uppercase" : "none";

  const eventIdBtnWidth = isMobile ? "" : "75%";

  return (
    <Card.Body
      className="px-4 pt-4 pb-0"
      style={{
        backgroundColor: landingPageStyle.backgroundColor,
        color: landingPageStyle.textColor
      }}
    >
      <h1 className="text-center mb-3 h5" style={{ fontWeight: "bold" }}>
        {yourEventId}
      </h1>
      <div className="px-3 mb-4">
        <p>{uniqueIdentifier}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: eventIdPurpose
          }}
        ></p>
      </div>
      <Row
        className={isMobile ? "p-3 mx-0 text-center" : "p-4 mx-0"}
        style={{ backgroundColor: "#edeeee", borderRadius: "8px" }}
      >
        <Col md={6} lg={6} xl={6} className={isMobile ? "mb-3" : "pl-0"}>
          <div className={isMobile ? "mb-3" : "mb-1"}>Event ID:</div>
          <div
            className={emailSent ? "" : "mb-2"}
            style={{
              textTransform: eventIdTextTransormStyle,
              fontWeight: "bold",
              fontSize: "20px"
            }}
          >
            {eventIdText}
          </div>
        </Col>
        <Col
          md={6}
          lg={6}
          xl={6}
          className={isMobile ? "px-0 mt-auto" : "pr-0 mt-auto text-right"}
        >
          {emailSent ? (
            <div
              style={{
                fontWeight: "bold",
                color: "#53565a",
                fontSize: "14px"
              }}
              aria-live="polite"
            >
              {eventIdEmailSuccess}
              <FaCheckCircle
                className="ml-2 mb-1"
                style={{ color: "#00c067" }}
                aria-hidden="true"
              />
            </div>
          ) : (
            <Button
              block={isMobile}
              variant="outline-secondary"
              style={{
                textAlign: "center",
                backgroundColor: "#fff",
                border: "2px solid #425CC7",
                width: eventIdBtnWidth
              }}
              onClick={handleEmailEventId}
              disabled={emailSentLoading}
            >
              {emailSentLoading ? (
                <ClipLoader size={18} color="#425CC7" />
              ) : (
                <div
                  style={{
                    color: "#425CC7",
                    fontSize: "16px",
                    fontWeight: 400
                  }}
                >
                  {emailEventId}
                  <FaRegEnvelope
                    className="ml-3 mb-1"
                    size="16px"
                    style={{ color: "#425CC7" }}
                  />
                </div>
              )}
            </Button>
          )}
        </Col>
      </Row>
    </Card.Body>
  );
};

export default EventIdv2;
