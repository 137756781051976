/* tslint:disable:no-empty */
import React from "react";
import TestTakerData from "../Interfaces/TestTakerData";
import LandingPageParameters from "../Interfaces/LandingPageParameters";
import { RevelianTestData } from "../Interfaces/RevelianTestData";

interface TestEventContext {
  eventId: string;
  jobCode: string;
  token: string;
  testEventId: number | null;
  testEventData: any;
  applicationIdsArray: string[];
  testIdsArray: string[];
  isResumingTest: boolean;
  usedEventId: boolean;
  expiredTime: number;
  eventIdCompleted: boolean;
  restartCounter: number;
  reachedRestartLimit: boolean;
  optionalReturnUrl: string | null;
  isMobile: boolean;
  orderId: string | null;
  source: string | null;
  completedSubTestIds: string[];
  testIndex: number;
  sameTest: boolean;
  testIdToResume: string | null;
  fromEventIdLink: boolean;
  testIdOfFirstTest: string | null;
  updateSameTest: (value: boolean) => void;
  updateExpiredTime: (value: number) => void;
  updateIsResumingTest: (value: boolean, testIdToResume: string | null) => void;
  sendTestTakerData: (url: string, method: string, data: TestTakerData) => void;
  generateLandingPageStyle: (
    landingPageParameters: LandingPageParameters
  ) => any;
  updateCompletedApplications: (applicationId: number) => any;
  updateResumeSubmitted: (value: number) => any;
  updateOtherDocsSubmitted: (documentId: string, value: boolean) => any;
  updateUsedEventId: (value: boolean) => void;
  eventExpirationDate: string | null;
  overviewExpiredMessage: string | null;
  updateExpiredMessage: () => void;
  updateWrongExpiredTimeMessage: () => void;
  expiredTimeErrorMessage: string | null;
  revelianTestData: RevelianTestData | null;
  isAU: boolean;
  lastQuestionNumber: number;
  updateLastQuestionNumber: (value: number) => void;
  configCatObject: any;
  companyAccountId: string;
  partnerAccountId: string | null;
  videoInterview: any;
  isVideoInterviewDiscoveryJob: boolean;
  videoInterviewDiscovery: {
    evaluationLink: string;
    invitationMsg: string;
    invitationDescription: string;
    welcome: string;
    completionMsg: string;
    reviewSampleCandidates: string;
    beginEvaluation: string;
  } | null;
}

export const TestEventContext = React.createContext<TestEventContext | null>({
  eventId: "",
  jobCode: "",
  token: "",
  testEventData: {},
  testEventId: null,
  applicationIdsArray: [],
  testIdsArray: [],
  isResumingTest: false,
  usedEventId: false,
  expiredTime: 0,
  eventIdCompleted: false,
  restartCounter: 0,
  reachedRestartLimit: true,
  optionalReturnUrl: null,
  isMobile: false,
  orderId: null,
  source: null,
  completedSubTestIds: [],
  testIndex: 0,
  sameTest: false,
  testIdToResume: null,
  fromEventIdLink: false,
  testIdOfFirstTest: null,
  updateSameTest: (value: boolean) => {},
  updateExpiredTime: (value: number) => {},
  sendTestTakerData: (url: string, method: string, data: TestTakerData) => {},
  generateLandingPageStyle: (
    landingPageParameters: LandingPageParameters
  ) => {},
  updateCompletedApplications: (applicationId: number) => {},
  updateIsResumingTest: (value: boolean, testIdToResume: string | null) => {},
  updateResumeSubmitted: (value: number) => {},
  updateOtherDocsSubmitted: (documentId: string, value: boolean) => {},
  updateUsedEventId: (value: boolean) => {},
  eventExpirationDate: null,
  overviewExpiredMessage: null,
  updateExpiredMessage: () => {},
  updateWrongExpiredTimeMessage: () => {},
  expiredTimeErrorMessage: null,
  revelianTestData: null,
  isAU: false,
  lastQuestionNumber: 0,
  updateLastQuestionNumber: (value: number) => {},
  configCatObject: null,
  companyAccountId: "",
  partnerAccountId: null,
  videoInterview: null,
  isVideoInterviewDiscoveryJob: false,
  videoInterviewDiscovery: null
});
export const TestEventProvider = TestEventContext.Provider;
export const TestEventConsumer = TestEventContext.Consumer;
