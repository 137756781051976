import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { TestEventContext } from "../Contexts/TestEventContext";
import { getTranslationLanguage, translate } from "../utils/translationHelpers";

const Footer = () => {
  const context = useContext(TestEventContext);

  const [language, setLanguage] = useState("");

  useEffect(() => {
    if (context && context.testEventData) {
      setLanguage(getTranslationLanguage(context.testEventData, context.isAU));
    }
  }, [context]);

  const linkNames = ["copyrightNotices", "terms", "privacy", "accommodations"];

  const FooterLinks = linkNames.map(linkName => (
    <a
      key={linkName}
      href={translate(`${linkName}Link`, language)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {translate(linkName, language)}
    </a>
  ));

  // NOTE: Commented out in case we bring back ODA Amplitude tracking - see https://criteria.atlassian.net/browse/PRO-11141
  // const getFooterText = () => {
  //   const hostnameArray = window.location.hostname.split(".");
  //   const subdomain = hostnameArray[0].toUpperCase();
  //   return {
  //     __html: `<p class="mb-0">Copyright &copy;${new Date().getFullYear()} Criteria Corp. | <a href="/home/copyright" target="_blank" onclick="onClickFooterMenuItem('copyright')" >Copyright Notices</a></p>
  //       <p class="mt-0"><a href="/home/terms" target="_blank" onclick="onClickFooterMenuItem('terms')">Terms of Use</a> | <a href="/home/privacy" target="_blank" onclick="onClickFooterMenuItem('privacy')">Privacy Policy</a> | <a href="/home/accommodations${
  //         (context && context.isAU) || subdomain === "AU" ? "/au" : ""
  //       }" target="_blank" onclick="onClickFooterMenuItem('accommodations')">Disability/Accommodations</a></p>`
  //   };
  // };

  return (
    <Row className="footer">
      <Col
        xl={{ span: 8, offset: 2 }}
        lg={{ span: 8, offset: 2 }}
        md={{ span: 10, offset: 1 }}
        sm={12}
        className="text-center"
      >
        {/* <div dangerouslySetInnerHTML={getFooterText()} /> */}
        <div>
          <p className="mb-0">
            {translate("copyright", language)} &copy;{new Date().getFullYear()}{" "}
            Criteria Corp. | {FooterLinks[0]}
          </p>
          <p className="mt-0">
            {FooterLinks[1]}
            {" | "}
            {FooterLinks[2]}
            {" | "}
            {FooterLinks[3]}
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default Footer;
