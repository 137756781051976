import Language from "../../Interfaces/Language";

export const turkish: Language = {
  help: "Yardıma mı ihtiyacınız var?",
  helpCenter: "Yardım Merkezi",
  helpCenterLink: "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/",
  expect: "Bu testlerden ne beklenmelidir",
  expectLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/collections/3396642-about-criteria-assessments",
  faq: "Sıkça Sorulan Sorular",
  faqLink: "/home/faq",
  copyright: "Telif Hakkı",
  copyrightNotices: "Telif Hakkı Bildirimleri",
  copyrightNoticesLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6815627-telif-hakki-bildirimi",
  terms: "Kullanım Şartları",
  termsLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6972002-kullanim-hukum-ve-kosullari",
  privacy: "Gizlilik Bildirimi",
  privacyLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6971995-gizlilik-politikasi",
  dmca: "DMCA Politikası",
  dmcaLink: "/home/dmca",
  accommodations: "Engellilik / Erişilebilirlik",
  accommodationsLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6971993-engellilik-erisilebilirlik",
  technicalSupport: "Teknik Destek",
  chatSupport:
    "Destek için lütfen bu sayfanın sağ alt köşesinden ekibimizle sohbet edin"
};
