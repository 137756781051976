import React, { useContext, useState } from "react";
import { Row, Col, Alert, Button } from "react-bootstrap";
import { TestEventContext } from "../Contexts/TestEventContext";
import Field from "./Field";
import checkCircle from "../Resources/images/check_circle.png";

interface Props {
  sendSmoketestEmailForm: (emailAddress: string) => Promise<any>;
}

const EmailInputSmoketest = ({ sendSmoketestEmailForm }: Props) => {
  const context: any = useContext(TestEventContext);

  const [userEmail, setUserEmail] = useState(
    context.testEventData.testTaker.testTakerEmail || ""
  );
  const [error, setError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleInput = (event: any) => {
    const target = event.currentTarget;
    const value = target.value;
    setUserEmail(value);
  };

  const handleSubmit = () => {
    if (
      userEmail &&
      userEmail.length > 0 &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userEmail)
    ) {
      setFormSubmitted(true);
      sendSmoketestEmailForm(userEmail);
      return;
    }
    setError("Please enter a valid email address");
  };

  return (
    <div>
      {!formSubmitted ? (
        <Col className="pt-2 pb-5">
          <Row className="justify-content-center">
            <h6 className="mb-4 text-center" style={{ fontSize: "16px" }}>
              Are you interested in receiving highly relevant job alerts from
              companies looking for great candidates?
            </h6>
          </Row>
          <Row>
            <Col>
              <Field
                label={
                  context.testEventData.translatedText
                    ? context.testEventData.translatedText.email
                    : "Your Email Address"
                }
                id="emailForSmokeTest"
                maxLength={50}
                fieldType="email"
                handleInput={handleInput}
                value={userEmail}
              />
              {error.length > 0 ? (
                <Alert variant="danger">{error}</Alert>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Button
              block={true}
              variant="primary"
              id="verify-form-submit-button"
              onClick={handleSubmit}
              style={{ width: "400px" }}
            >
              Yes! Send me more information.
            </Button>
          </Row>
        </Col>
      ) : (
        <Row className="justify-content-center">
          <Col>
            <h4 className="mb-4 mt-4 text-center">
              <strong>Great!</strong>
            </h4>
            <h6 className="mb-4 text-center">Thank you for your interest.</h6>
            <Row className="justify-content-center">
              <img
                className="mb-4 text-center"
                src={checkCircle}
                alt="checkmark"
              />
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default EmailInputSmoketest;
