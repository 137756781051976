import React, { Component } from "react";
import ApplicationQuestions from "../../Interfaces/ApplicationQuestions";
import { Form } from "react-bootstrap";

interface Props {
  question: ApplicationQuestions;
  missingQuestions: string[];
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default class ApplicationCheckbox extends Component<Props> {
  render() {
    const { missingQuestions, onChangeHandler } = this.props;
    const { applicationQuestionId, label, selectList } = this.props.question;
    // if the question was required, and the application was submitted without an answer, change the
    // background color as an indication.
    const backgroundColor = missingQuestions.includes(
      `q_${applicationQuestionId}`
    )
      ? "#F7CECD"
      : "";

    return (
      <Form.Group>
        <Form.Label
          style={{ backgroundColor }}
          htmlFor={`${applicationQuestionId}`}
        >
          {label}
        </Form.Label>
        <Form.Check type="checkbox" id={`q_${applicationQuestionId}`}>
          {Object.keys(selectList.options).map(key => (
            <React.Fragment>
              <Form.Check
                type="checkbox"
                name={`q_${applicationQuestionId}`}
                id={`q_${applicationQuestionId}`}
                value={key}
                onChange={onChangeHandler}
                label={selectList.options[key]}
              />
            </React.Fragment>
          ))}
        </Form.Check>
      </Form.Group>
    );
  }
}
