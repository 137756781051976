/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState, useEffect } from "react";
import { Card, Container, Row, Col, ListGroup, Alert } from "react-bootstrap";
import Header from "../Components/Header";
import {
  TestEventConsumer,
  TestEventContext
} from "../Contexts/TestEventContext";
import { Redirect, navigate } from "@reach/router";
import PageContinueButton from "../Components/PageContinueButton";
import {
  getApplications,
  getVideoInterview,
  getDocuments,
  getTests,
  generateNewToken,
  setRestartCounter
} from "./helpers";
import {
  personalityTests,
  nonMobileTests,
  multipleChoiceTests,
  isEventExpired,
  revelianSubTests,
  checkIsOneHourBeforeExpiration
} from "../utils/shared";
import { getMultipleChoiceStartingQuestion } from "../utils/redisHelpers";
import Footer from "../Components/Footer";
import { browserName } from "react-device-detect";
import warningIcon from "../Resources/images/warning.png";

interface Props {
  path: string;
  updateToken: (token: string) => void;
  updateReachedRestartLimit: () => void;
  updateRestartCounter: () => void;
  updateExpiredTime: (value: number) => void;
  userDisqualified: boolean;
  updateLastQuestionNumber: (value: number) => void;
}

const Overview = ({
  updateToken,
  updateReachedRestartLimit,
  updateRestartCounter,
  updateExpiredTime,
  userDisqualified,
  updateLastQuestionNumber
}: Props) => {
  const [goToTests, setGoToTests] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [invalidTokenErrorMessage, setInvalidTokenErrorMessage] = useState("");
  const [
    tooManyRestartsErrorMessage,
    setTooManyRestartsErrorMessage
  ]: any = useState("");
  const [restartWarningMessage, setRestartWarningMessage]: any = useState("");
  const [expiredMessage, setExpiredMessage] = useState("");

  const context = useContext(TestEventContext);

  const revelianTestSupportedBrowsers = [
    "Chrome",
    "Firefox",
    "Safari",
    "Mobile Safari",
    "Edge"
  ];

  let modifiedRestartCounter: any;

  let testEventData = context?.testEventData;

  useEffect(() => {
    if (!context) {
      return;
    }
    testEventData = context.testEventData;
    if (
      testEventData?.completed?.videoInterview &&
      testEventData?.completed?.assessment
    ) {
      navigate("/video-interview-complete/" + testEventData.eventId);
    }
  }, [context]);

  useEffect(() => {
    if (!context) {
      return;
    }
    // Determine if the event is expired, and if so update the expired message so it shows on the page
    if (isEventExpired(context.eventExpirationDate)) {
      context.updateExpiredMessage();
    }

    // determine if we need to show a restart warning, and set the state accordingly.
    if (
      !personalityTests.includes(context.testIdsArray[context.testIndex]) &&
      (context.isResumingTest || context.usedEventId)
    ) {
      modifiedRestartCounter =
        context.testIdOfFirstTest &&
        personalityTests.includes(context.testIdOfFirstTest)
          ? context.restartCounter + 1
          : context.restartCounter;

      switch (modifiedRestartCounter) {
        case 3: {
          const warningMessage = testEventData.translatedText
            ? {
                __html: testEventData.translatedText.restartOnce
              }
            : {
                __html:
                  "For test security reasons, once you click “Continue” you can only restart this event 1 more time."
              };
          setRestartWarningMessage(warningMessage);
          break;
        }
        case 4:
          {
            const warningMessage = testEventData.translatedText
              ? {
                  __html: testEventData.translatedText.cannotRestart
                }
              : {
                  __html:
                    "For test security reasons, once you click “Continue” you cannot restart this event again."
                };
            setRestartWarningMessage(warningMessage);
          }
          break;
        case 5:
          fetchErrorMessage(
            context.testIdsArray,
            context.testIndex,
            context.testEventId,
            context.token
          );
          break;
        default:
          break;
      }
    }
    if (
      context.testEventId &&
      multipleChoiceTests.includes(context.testIdsArray[context.testIndex])
    ) {
      const resumeTestData = getMultipleChoiceStartingQuestion(
        context.testEventId,
        context.testIdsArray[context.testIndex],
        context.token
      );
      resumeTestData.then((statusData: any) => {
        updateExpiredTime(Number(statusData.expiredTime));
        updateLastQuestionNumber(Number(statusData.questionNumber));
      });
    }

    return () => {
      setTooManyRestartsErrorMessage("");
    };
  }, []);

  // if the goToTests state value changes to true, we need to get an updated token
  useEffect(() => {
    if (!context) {
      return;
    }
    if (goToTests) {
      const getNewToken = generateNewToken(
        context.testEventId,
        context.eventId,
        testEventData.testTaker.testTakerId
      );
      getNewToken.then(response => {
        if (response) {
          updateToken(response);
          setValidToken(true);
        } else {
          setInvalidTokenErrorMessage(
            "There was a problem with your assessment."
          );
        }
      });
    }
  }, [goToTests, updateToken]);

  useEffect(() => {
    if (!context) {
      return;
    }
    if (context.overviewExpiredMessage) {
      const modifiedMessage = context.overviewExpiredMessage
        ?.replace(
          "{[eventId]}",
          `${context.eventId.slice(0, 3)}-${context.eventId.slice(
            3,
            7
          )}-${context.eventId.slice(7)}`
        )
        .replace("{[companyName]}", testEventData?.companyName);
      setExpiredMessage(modifiedMessage);
    }
  }, [context?.overviewExpiredMessage]);

  // if the validToken state value is changed to true, we need to update the restart counter and either navigate to
  // the tests route, or display error message.
  useEffect(() => {
    if (!context) {
      return;
    }
    if (validToken) {
      if (!context.sameTest) {
        context.updateSameTest(true);
      }
      if (
        context.isMobile &&
        (nonMobileTests.includes(context.testIdsArray[context.testIndex]) ||
          testEventData.tests[context.testIdsArray[context.testIndex]].details
            .isTyping)
      ) {
        navigate("/desktop-only");
      } else if (
        revelianSubTests.includes(context.testIdsArray[context.testIndex]) &&
        !revelianTestSupportedBrowsers.includes(browserName)
      ) {
        navigate("/unsupported-browser");
      } else {
        navigate("/tests");
      }
    }
  }, [validToken, updateReachedRestartLimit, updateRestartCounter]);

  if (!context || Object.keys(context.testEventData).length === 0) {
    return (
      <TestEventConsumer>
        {context => <Redirect from="verify" to="/" noThrow={true} />}
      </TestEventConsumer>
    );
  }

  // initialize the item number here, globally, so we can track it across several method calls.
  let itemNumber: number = 1;

  // get a list of all applications
  const applications: any[] =
    testEventData && testEventData.application
      ? getApplications(
          testEventData.application,
          itemNumber,
          testEventData.landingPage.backgroundColor,
          testEventData.landingPage.textColor,
          userDisqualified
        )
      : [];

  // increment the itenNumber global variable for use with documents
  itemNumber = itemNumber + applications.length;

  // get a list of a requested documents
  const documents: any[] =
    testEventData &&
    (testEventData.documents || testEventData.requireResume > 0)
      ? getDocuments(
          testEventData,
          itemNumber,
          testEventData.landingPage.backgroundColor,
          testEventData.landingPage.textColor
        )
      : [];

  itemNumber = itemNumber + documents.length;

  const hasVideoInterviews =
    context.videoInterview !== null || testEventData.videoInterview !== null;
  const sortOrder = context.testEventData?.sortOrder;
  const videoInterviewBeforeAssessments =
    sortOrder && sortOrder[0] === "Video Interview";

  let viItemNumber = itemNumber;

  // if VI is in the event and comes before assessments, increment itemNumber by 1 for tests display
  // NOTE: if we ever implement multiple VIs in one ODA event, will need to update increment # here
  if (videoInterviewBeforeAssessments) {
    itemNumber = itemNumber + 1;
  } else {
    // otherwise, add total subtest count to number displayed for VI
    const totalSubTests = testEventData.tests
      ? Object.keys(testEventData.tests).length
      : 0;
    viItemNumber = itemNumber + totalSubTests;
  }

  const tests: any[] =
    testEventData && testEventData.landingPage && testEventData.tests !== null
      ? getTests(
          testEventData,
          itemNumber,
          context.isMobile,
          context.isResumingTest,
          context.expiredTime,
          testEventData.landingPage.backgroundColor,
          testEventData.landingPage.textColor,
          context.completedSubTestIds,
          context.testIdsArray,
          context.testIndex,
          context.testIdToResume,
          context.lastQuestionNumber
        )
      : [];

  const videoInterviews: any[] =
    testEventData && testEventData.landingPage && hasVideoInterviews
      ? getVideoInterview(viItemNumber, testEventData)
      : [];

  // if other documents have been requested, check that object to see if they have all been completed.
  // if they have, we can skip that part of the conditional in the handle continue method and go to tests,
  // otherwise, we need to route to the other docs
  let allDocsReceived: boolean = true;
  if (testEventData && testEventData.documents) {
    testEventData.documents.files.map((file: any) => {
      if (!file.completed) {
        allDocsReceived = false;
      }
      return false;
    });
  }

  const handleVIRedirection = (
    token: string,
    testEventId: number | null,
    redirectUrl: string
  ) => {
    setRestartCounter(
      context.testIdsArray,
      context.testIndex,
      testEventId,
      token,
      true
    ).then(res => {
      if (res.error) {
        return;
      }

      window.location.href = redirectUrl;
    });
  };

  const handleStartVI = () => {
    // This is necessary in case the candidate has just been redirected from a Revelian test
    if (!context.token) {
      const getNewToken = generateNewToken(
        testEventData.testEventId,
        testEventData.eventId,
        testEventData.testTaker.testTakerId
      );
      getNewToken
        .then(token => {
          if (token) {
            handleVIRedirection(
              token,
              testEventData.testEventId,
              testEventData.videoInterview.redirectUrl
            );
          } else {
            setInvalidTokenErrorMessage(
              "There was a problem with your Video Interview."
            );
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      handleVIRedirection(
        context.token,
        context.testEventId,
        context.videoInterview.redirectUrl
      );
    }
  };

  const handleContinueButton = () => {
    // we first must check if the event has expired when the candidate attempts to continue.
    if (isEventExpired(context.eventExpirationDate)) {
      context.updateExpiredMessage();
      return;
    }

    if (context.applicationIdsArray && context.applicationIdsArray.length > 0) {
      navigate("/application");
      return;
    }

    if (!testEventData) {
      return;
    }

    const requiredResume = testEventData.requireResume > 0;
    const resumeComplete = testEventData.resumeComplete === 1;

    if (requiredResume && !resumeComplete) {
      navigate("/document_upload/resume");
      return;
    } else if (!allDocsReceived) {
      // determine which file is currently being collected, and get that documentId to pass along in the url.
      const currentFile = testEventData.documents.files.filter((file: any) => {
        return !file.completed;
      });
      navigate(`/document_upload/other_docs/${currentFile[0].documentId}`);
      return;
    }

    const videoInterviewIncomplete =
      hasVideoInterviews && !testEventData.completed.videoInterview;
    const assessmentsIncomplete = !testEventData.completed.assessment;

    if (videoInterviewIncomplete && videoInterviewBeforeAssessments) {
      handleStartVI();
    } else if (assessmentsIncomplete && testEventData.tests) {
      setGoToTests(true);
    } else if (videoInterviewIncomplete) {
      handleStartVI();
    } else if (testEventData.tests === null) {
      // Navigating to /tests will end the test event
      navigate("/tests");
    }
  };

  // If the candidate has restarted more than 5 times then ping the API to get the updated/correct message.
  const fetchErrorMessage = async (
    testIdsArray: string[],
    testIndex: number,
    testEventId: number | null,
    token: string
  ) => {
    const result = await setRestartCounter(
      testIdsArray,
      testIndex,
      testEventId,
      token
    );
    setTooManyRestartsErrorMessage({ __html: result.error });
  };

  // check whether the testEvent will expire in an hour
  const isOneHourBeforeExpiration: boolean = checkIsOneHourBeforeExpiration(
    context
  );

  let componentArray = [
    { id: 1, component: applications },
    { id: 2, component: documents },
    { id: 3, component: tests },
    { id: 4, component: videoInterviews }
  ];

  if (videoInterviewBeforeAssessments) {
    componentArray = [
      { id: 1, component: applications },
      { id: 2, component: documents },
      { id: 3, component: videoInterviews },
      { id: 4, component: tests }
    ];
  }

  const landingPageStyle = context.generateLandingPageStyle(
    testEventData.landingPage
  );
  modifiedRestartCounter =
    context.testIdOfFirstTest &&
    personalityTests.includes(context.testIdOfFirstTest)
      ? context.restartCounter + 1
      : context.restartCounter;

  return (
    <Container>
      <Row>
        <Col
          xl={{ span: 8, offset: 2 }}
          lg={{ span: 8, offset: 2 }}
          md={{ span: 10, offset: 1 }}
          sm={12}
        >
          <Card className="oda-card">
            {" "}
            <Header
              onWelcomePage={false}
              backgroundColor={landingPageStyle.backgroundColor}
              customLogo={testEventData.logo}
              isVideoInterviewDiscoveryJob={
                context.isVideoInterviewDiscoveryJob
              }
            />
            <Card.Body
              style={{
                backgroundColor: landingPageStyle.backgroundColor,
                color: landingPageStyle.textColor
              }}
            >
              <Card.Title className="text-center">
                {testEventData.translatedText ? (
                  <h1
                    className="h5"
                    dangerouslySetInnerHTML={{
                      __html: testEventData.translatedText.overview
                    }}
                  />
                ) : (
                  <strong>Overview</strong>
                )}
              </Card.Title>
              {testEventData.translatedText ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: testEventData.translatedText.toDoList
                  }}
                />
              ) : (
                <p>Today you'll be completing the following: </p>
              )}
              <ListGroup id="overview" as="ol">
                {componentArray.map(item => (
                  <React.Fragment key={item.id}>
                    {item.component}
                  </React.Fragment>
                ))}
              </ListGroup>
              {expiredMessage ? (
                <Alert variant="danger">{expiredMessage}</Alert>
              ) : null}
              {context.testIdsArray &&
              context.testIdsArray.length > 0 &&
              context.eventExpirationDate !== null &&
              !tooManyRestartsErrorMessage &&
              !isOneHourBeforeExpiration &&
              !isEventExpired(context.eventExpirationDate) ? (
                <Col
                  className="mt-3 pr-3 pl-0 py-2"
                  style={{
                    backgroundColor: "#f9e5eb"
                  }}
                >
                  <Row className="mx-0">
                    <Col
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      className="px-0 text-right"
                    >
                      <img
                        height="16px"
                        width="16px"
                        src={warningIcon}
                        alt=""
                        className="mr-2 mb-1"
                      />
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} className="px-0">
                      <p
                        className="mb-0"
                        style={{
                          fontSize: "14px",
                          color: "#e11f3b"
                        }}
                      >
                        {testEventData.translatedText ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                testEventData.translatedText
                                  .giveYourselfTimeWarning
                            }}
                          />
                        ) : null}
                      </p>
                    </Col>
                  </Row>
                </Col>
              ) : null}
              {isOneHourBeforeExpiration && !tooManyRestartsErrorMessage ? (
                <Col
                  className="mt-3 pr-3 pl-0 py-2"
                  style={{
                    backgroundColor: "#f9e5eb"
                  }}
                >
                  <Row className="mx-0">
                    <Col
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      className="px-0 text-right"
                    >
                      <img
                        height="16px"
                        width="16px"
                        src={warningIcon}
                        alt=""
                        className="mr-2 mb-1"
                      />
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} className="px-0">
                      <p
                        className="mb-0"
                        style={{
                          fontSize: "14px",
                          color: "#e11f3b"
                        }}
                      >
                        {testEventData.translatedText ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                testEventData.translatedText.hourLeftWarning
                            }}
                          />
                        ) : null}
                      </p>
                    </Col>
                  </Row>
                </Col>
              ) : null}
            </Card.Body>
            <Card.Footer
              style={{
                borderTop: 0,
                backgroundColor: landingPageStyle.backgroundColor
              }}
            >
              {invalidTokenErrorMessage ? (
                <Alert variant="danger" className="text-center">
                  {invalidTokenErrorMessage}
                </Alert>
              ) : null}
              {!invalidTokenErrorMessage && tooManyRestartsErrorMessage ? (
                <Alert variant="danger" className="text-center">
                  <p dangerouslySetInnerHTML={tooManyRestartsErrorMessage} />
                </Alert>
              ) : null}
              {restartWarningMessage ? (
                <Alert variant="danger" className="text-center">
                  <div dangerouslySetInnerHTML={restartWarningMessage} />
                </Alert>
              ) : null}
              {context.expiredTimeErrorMessage ? (
                <Alert variant="danger" className="text-center">
                  {context.expiredTimeErrorMessage}
                </Alert>
              ) : null}

              <br />
              <PageContinueButton
                handler={handleContinueButton}
                buttonText={
                  testEventData.translatedText
                    ? testEventData.translatedText.continueButton
                    : "Continue"
                }
                disabled={
                  (modifiedRestartCounter > 4 &&
                    (context.isResumingTest || context.usedEventId)) ||
                  userDisqualified
                }
              />
            </Card.Footer>
          </Card>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default Overview;
